import React, { useState, useEffect, useCallback, useRef } from "react"
import { Link } from "gatsby"
// ---------------------------------------------
import { NewsletterPopup } from "../components/newsletter"
import Markdown from "../components/markdown"
import useSettings from "./useSettings"

// function checkStorage(WrappedComponent) {

//   if (process.browser && localStorage) {
//     window.onbeforeunload = function () {
//       localStorage.clear()
//     }
//     return (props) => {
//       const hasSignedUp = localStorage.getItem('hasSignedUp');
//       return <WrappedComponent {...props} hasSignedUp={hasSignedUp} />
//     }
//   } else {
//     return (props) => <WrappedComponent {...props} hasSignedUp={false} />
//   }
// }

const FooterLinks = ({ title, links }) => (
	<div className="md span-3 pad">
		<p>{title}</p>
		<div>
			{links.map((node) => {
				return (
					<div key={`${node.label}-footer`}>
						<Link to={`/${node.link.slug}`}>
							{node.label}
						</Link>
					</div>
				)
			})}
		</div>
	</div>
)

const Footer = ({ bg, newsletterState }) => {

	const { showNewsletter, handleNewsletterOpen } = newsletterState
  const { 
		legal, 
		footerAddressCopy, 
		footerContactCopy, 
		footerOtherLinks, 
		footerOtherLinksTitle 
	} = useSettings()

  return (
    <footer style={bg && {
      backgroundColor: bg
    }}>
      <div className="flex-grid has-links">
        <div className="md span-3 pad">
          <Markdown field={footerContactCopy} />
					<p style={{
						transition: 'opacity 1s',
						opacity: !showNewsletter ? 1 : 0,
						pointerEvents: !showNewsletter ? 'all' : 'none'
					}}>
						<button 
							type="button" 
							className="link pointer" 
							onClick={handleNewsletterOpen}
						>
							Sign up to our Newsletter
						</button>
					</p>
        </div>
        <div className="md span-3 pad">
          <Markdown field={footerAddressCopy} />
        </div>
				<FooterLinks title="CUSTOMER CARE" links={legal} />
				<FooterLinks title={footerOtherLinksTitle} links={footerOtherLinks} />
      </div>
    </footer>
  )
}

export default Footer
