import React from "react"
// ---------------------------------------------
import Header from "../components/header"
import Footer from "../components/footer"
import { NewsletterPopup, useNewsletterPopup } from "./newsletter"
// ---------------------------------------------
import "../css/app.scss"


const Layout = ({ children, footerColour, hasSplash, removeSplash }) => {

	const newsletterState = useNewsletterPopup()

  return (
    <>
      <Header />
      <main>
        {children}
        <Footer bg={footerColour} newsletterState={newsletterState} />
				{(!hasSplash || (hasSplash && removeSplash)) && <NewsletterPopup {...newsletterState} />}
      </main>
    </>
  )
}

export default Layout
