import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import useCookie from "react-use-cookie"

const Input = ({ type, name }) => {
  const [value, set] = useState("")
  return <input type={type || "text"} name={name} value={value} onChange={e => set(e.target.value)} />
}

export function useNewsletterPopup() {

	const [hasSignedUp, setHasSignedUp] = useCookie('sedilia_is_subscribed', false);
	const [showNewsletter, setShowNewsletter] = useState(false)

  useEffect(() => {
		let timer
		const revealNewsletter = () => {
      setShowNewsletter(true)
    }
		if (!hasSignedUp || hasSignedUp === 'false') {
      timer = setTimeout(revealNewsletter, 3000)
    }
		return () => clearTimeout(timer)
	}, [hasSignedUp])

	const handleNewsletterClose = () => {
		setShowNewsletter(false)
		setHasSignedUp(true)
	} 

	const handleNewsletterOpen = () => {
		setShowNewsletter(true)
		setHasSignedUp(false)
	}

	return {
		hasSignedUp,
		setHasSignedUp,
		showNewsletter,
		setShowNewsletter,
		handleNewsletterOpen,
		handleNewsletterClose
	}
}

export const NewsletterPopup = ({ showNewsletter, handleNewsletterClose }) => {

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(showNewsletter)
  }, [showNewsletter])

  return (
    <div className="newsletter-form-popup w100" style={{
      transform: `translateY(${visible ? '0%' : '100%'})`,
      transition: 'transform 1s'
    }}>
			<button type="button" className="newsletter-close" onClick={handleNewsletterClose}>×</button>
      <div className="padx2">
        <p className="mb">
					<b className="mr inlb">Sign up to our Newsletter</b>
					<span className="inlb">Read our <Link to="/privacy-policy" className="link">Privacy Policy</Link></span>
				</p>
        <form action="https://sedilia.us20.list-manage.com/subscribe/post?u=44c2bad3191801d321d3ebd81&amp;id=7fb84a5668" method="post" target="_blank" noValidate>
          <div style={{
            opacity: 1,
            transition: 'opacity 0.2s'
          }}>
            <label>
              First name
            </label>
            <Input type="text" name="FNAME" />
            <span>, </span>
            <label>
              Last name
            </label>
            <Input type="text" name="LNAME" />
            <span>, </span>
            <label>
              Email
            </label>
            <Input type="email" name="EMAIL" />
            <div style={{
              position: 'absolute',
              left: -9999
            }} aria-hidden="true">
              <input type="text" name="b_44c2bad3191801d321d3ebd81_7fb84a5668" tabIndex="-1" value="" readOnly />
            </div>
						<button type="submit" className="btn submit">Subscribe</button>
          </div>
        </form>
      </div>
    </div>
  )
}

// export const NewsletterHomepage = () => {

//   return (
//     <div className="newsletter-form-homepage">
//       <div className="padx2">
//         <p className="mb">{'Sign up to our Newsletter'} <span className="h4">Read our <span className="link">Privacy Policy</span></span></p>
//         <form action="https://sedilia.us20.list-manage.com/subscribe/post?u=44c2bad3191801d321d3ebd81&amp;id=7fb84a5668" method="post" target="_blank" noValidate>
//           <div style={{
//             opacity: 1,
//             transition: 'opacity 0.2s'
//           }}>
//             <label>
//               First name
//             </label>
//             <Input type="text" name="FNAME" />
//             <span>, </span>
//             <label>
//               Last name
//             </label>
//             <Input type="text" name="LNAME" />
//             <span>, </span>
//             <label>
//               Email
//             </label>
//             <Input type="email" name="EMAIL" />
//             <div style={{
//               position: 'absolute',
//               left: -9999
//             }} aria-hidden="true">
//               <input type="text" name="b_44c2bad3191801d321d3ebd81_7fb84a5668" tabIndex="-1" value="" readOnly />
//             </div>
//             <input type="submit" value={'Sign up'} />
//           </div>
//         </form>
//       </div>
//     </div>
//   )
// }

// export const NewsletterInline = ({ showNewsletter }) => {

//   const [visible, setVisible] = useState(false);

//   useEffect(() => {
//     setVisible(showNewsletter)
//   }, [showNewsletter])

//   return (
//     <div className="footer-newsletter">
//       <div>
//         <p className="link pointer" onClick={e => setVisible(true)}>{'Sign up to our Newsletter'}</p>
//         {/* <h4>Read our <span className="link">Privacy Policy</span></h4> */}

//         {visible && (
//           <form action="https://sedilia.us20.list-manage.com/subscribe/post?u=44c2bad3191801d321d3ebd81&amp;id=7fb84a5668" method="post" target="_blank" noValidate>
//             <div className="outerx2">
//               <div className="field">
//                 <label>
//                   First name
//                 </label>
//                 <Input type="text" name="FNAME" />
//               </div>
//               <div className="field">
//                 <label>
//                   Last name
//                 </label>
//                 <Input type="text" name="LNAME" />
//               </div>
//               <div className="field">
//                 <label>
//                   Email
//                 </label>
//                 <Input type="email" name="EMAIL" />
//               </div>
//               <div style={{
//                 position: 'absolute',
//                 left: -9999
//               }} aria-hidden="true">
//                 <input type="text" name="b_44c2bad3191801d321d3ebd81_7fb84a5668" tabIndex="-1" value="" readOnly />
//               </div>
//               <button type="submit" className="outerx2 btn" value={'Sign up'} />
//             </div>
//           </form>
//         )}
//       </div>
//     </div>
//   )
// }
