import { graphql, useStaticQuery } from "gatsby"

export default function useSettings() {
  const data = useStaticQuery(graphql`
    {
      allContentfulSettings {
        nodes {
          title
          description
          shareImage {
            fluid(maxWidth: 800, quality: 70) {
              src
            }
          }
          menu {
            label
            slug
            link {
              __typename
              ... on ContentfulMaterialsPage {
                id
                slug
                label: title
              }
              ... on ContentfulPage {
                id
                slug
                label: title
              }
            }
            submenu {
              __typename
              ... on ContentfulProduct {
                id
                label: title
                slug
              }
              ... on ContentfulSettingsMenuItem {
                id
                label
                slug
                link {
                  __typename
                  ... on ContentfulMaterialsPage {
                    id
                    slug
                    label: title
                  }
                  ... on ContentfulPage {
                    id
                    slug
                    label: title
                  }
                }
                submenu {
                  ... on ContentfulProduct {
                    id
                    slug
                    label: title
                  }
                }
              }
            }
          }
          legal: customerCareFooter {
            label
            slug
            link {
              __typename
              ... on ContentfulPage {
                id
                slug
                label: title
              }
            }
          }
          footerContactCopy {
            childMarkdownRemark {
              html
            }
          }
          footerAddressCopy {
            childMarkdownRemark {
              html
            }
          }
          customiseCopy {
            childMarkdownRemark {
              html
            }
          }
          footerOtherLinksTitle
          footerOtherLinks {
            label
            slug
            link {
              __typename
              ... on ContentfulPage {
                id
                slug
                label: title
              }
            }
          }
          contactMenuCopy {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  return data.allContentfulSettings.nodes[0]
}
