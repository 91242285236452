import React, { useState } from "react"
import { Link } from "gatsby"
// ---------------------------------------------
import Hyperlink from "../components/hyperlink"
import useSettings from "./useSettings"
// ---------------------------------------------
import logo from "../images/logo.svg"

const ContactMenu = ({ contactMenuCopy, showContacts }) => (
  <div className={`contact-submenu has-links ${showContacts ? "show" : ""}`}>
    <div
      dangerouslySetInnerHTML={{
        __html: contactMenuCopy.childMarkdownRemark.html,
      }}
    />
  </div>
)

const Header = ({ data }) => {
  // StaticQuery is set in <Layout /> and passed down...
  const { menu, ...settings } = useSettings()

  // UX
  const [showMenu, setShowMenu] = useState(false)
  const [showContacts, setShowContacts] = useState(false)

  console.log(settings?.contactMenuCopy)

  return (
    <header onMouseLeave={e => setShowContacts(false)}>
      {/* Main header content */}
      <div className="flex-baseline bg-white main-menu">
        <div className="branding">
          <Link
            to="/"
            className="logo-link"
            style={{
              transform: `translateY(1px)`,
            }}
          >
            <img src={logo} className="logo" alt="Sedilia — Logotype" />
          </Link>
          <span className="gutters hide-on-mobile">
            Makers Designers, Designers Makers
          </span>
          <span className="gutters show-on-mobile">
            <span onTouchEnd={e => setShowMenu(true)}>Menu</span>
            <span>{", "}</span>
            <span onClick={e => setShowContacts(true)}>Contact Us</span>
          </span>
        </div>

        <nav className="desktop gutters hide-on-mobile">
          {menu.map(node =>
            node.submenu ? (
              <SubMenu {...node} key={node.label} />
            ) : (
              <MenuLink {...node} key={node.label} />
            )
          )}
          <span className="pointer" onClick={e => setShowContacts(true)}>
            Contact Us
          </span>
        </nav>
      </div>

      {/* Contact menu dropdown */}
      <ContactMenu {...settings} showContacts={showContacts} />

      {/* Mobile menu */}
      <nav
        className="mobile show-on-mobile"
        style={{
          transform: `translateX(${showMenu ? "0%" : "-100%"})`,
          transition: "0.5s",
        }}
      >
        <div className="branding flex-baseline mb">
          <Link to="/" className="logo-link">
            <img src={logo} className="logo" alt="Sedilia — Logotype" />
          </Link>
          <span className="gutters">
            <span onTouchEnd={e => setShowMenu(false)}>Close</span>
          </span>
        </div>
        {menu.map(node => {
          return (
            <div
              className="pad"
              key={`${node.label}-mobile`}
              style={{
                borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
              }}
            >
              {node.submenu ? (
                <button className="mb">{node.label}</button>
              ) : (
                <MenuLink {...node} key={node.label} />
              )}
              {node.submenu && (
                <LinkList links={node.submenu} label={node.label} />
              )}
            </div>
          )
        })}
      </nav>
    </header>
  )
}

const MenuLink = node => {
  // TODO: Refactor this
  let path = "/"
  let slug = node.slug
  if (node.link && node.link.__typename === "ContentfulPage") {
    slug = node.link.slug
  } else if (node.link && node.link.__typename === "ContentfulProduct") {
    path = "/"
    slug = node.link.slug
  } else if (node.link && node.link.__typename === "ContentfulMaterialsPage") {
    path = "/materials/"
    slug = node.link.slug
  }
  return <Link to={`${path}${slug}`}>{node.label}</Link>
}

const SubMenuGroup = ({ label, submenu }) => {
  const [open, setOpen] = useState(false)

  return (
    <li>
      <button className={open ? "open" : ""} onClick={() => setOpen(o => !o)}>
        {label}
      </button>
      {open && <LinkList links={submenu} label={label} />}
    </li>
  )
}

const SubMenu = ({ label, submenu }) => (
  <div className="menu-item sub-menu-wrapper">
    <span className="pointer">{label}</span>
    <div className="pabs sub-menu">
      <LinkList links={submenu} label={label} />
    </div>
  </div>
)

const LinkList = ({ label, links }) => {
  return (
    <ul>
      {links.map((item, i) => {
        return item.submenu ? (
          <SubMenuGroup {...item} key={`${label}-submenu-${i}`} />
        ) : (
          <li key={`${label}-submenu-${i}`}>
            <MenuLink {...item} log />
          </li>
        )
      })}
    </ul>
  )
}

export default Header
